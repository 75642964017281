export const masterDataUpdateFormLabels : Record<string, string> = {
    "firstName":"Fornavn",
    "middleName":"Mellemnavn",
    "lastName":"Efternavn",
    "addressLine1":"Vej og husnummer",
    "addressLine2":"Adresselinje 2",
    "postalCode":"Postnummer",
    "city":"By",
    "email":"Email",
    "mobilePhone":"Telefonnummer",
    "primaryPhone":"Primær telefon",
    "language":"Sprogpræference",
    "country":"Land",
};