import React, { useEffect } from "react";
import style from "./master-data.module.scss";
import * as api from "../../../api/services/ContactsService";
import { ContactRead } from "../../../api";
import Loader from "../../_GenericElements/Loader/loader";
import { ProfileElement } from "../../../models/umbracoElement";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import { SelfEmployed } from "../../../constants/common-constants";
import { StandardButton } from "../../_GenericElements/StandardButton/StandardButton";

export interface IMasterDataReadProps {
    data: string;
}

export const MasterDataRead = (props: IMasterDataReadProps) => {
    const umbracoSettings: ProfileElement = JSON.parse(props.data ?? "{}");
    const [masterdata, setMasterdata] = React.useState<ContactRead>();

    useEffect(() => {
        void (async () => {
            let contactData = await api.ContactsService.getContact();
            if (contactData) {
                setMasterdata(contactData);
            }
        })();

    }, []);

    const onUpdateBtnClick = () => {
        const editUrl = umbracoSettings.editLink._url;
        window.location.pathname = editUrl;
    };

    if (!masterdata) {
        return <Loader />;
    }


    let fields: { label: string, value?: string | null }[] = [
        { label: umbracoSettings.emailLabel || "Email", value: masterdata.email },
        { label: umbracoSettings.telefonLabel || "Mobil", value: masterdata.mobilePhone },
        { label: umbracoSettings.statusLabel || "Status", value: masterdata.status },
        {label:umbracoSettings.paymentGroupName||"Kontingentgruppe",value:masterdata.agreementGroup||""},
    ];

    if (masterdata.mainEmployment) {
        if (masterdata.mainEmployment.jobPosition) {
            fields.push({ label: umbracoSettings.stillingLabel || "Stilling", value: masterdata.mainEmployment.jobPosition.primaryName });
        }

        let workPlaceLabel = masterdata.mainEmployment.employmentType?.primaryName == SelfEmployed ?
            umbracoSettings.firmaNavnLabel || "Firma navn" :
            umbracoSettings.ansaettelsesstedLabel || "Ansættelsessted";

        if (masterdata.mainEmployment.newPlaceOfWork) {
            fields.push({ label: workPlaceLabel, value: masterdata.mainEmployment.newPlaceOfWork.primaryName });
        } else if (masterdata.mainEmployment.placeOfEmploymentName) {
            fields.push({ label: workPlaceLabel, value: masterdata.mainEmployment.placeOfEmploymentName });
        }
    }

    if (masterdata.mainEducation) {
        fields.push({ label: umbracoSettings.titelLabel || "Uddannelsesniveau og fagområde", value: masterdata.mainEducation.name });
    }

    return <div className={style.root}>
        <h2 className={style.header}>
            {masterdata.fullName}
        </h2>
        <table className={style.masterdata}>
            <tbody>
                {fields.map((f) => {
                    return <LabelValue trClassName={style.profilelabelvalue} key={f.label} label={f.label}
                        value={f.value}></LabelValue>;
                })}
            </tbody>

        </table>
        <div className={`${style.updatebtn}`} >
            <StandardButton  onClick={onUpdateBtnClick}>Se alle oplysninger om profil og kontingent</StandardButton>
        </div>
    </div>;
};

