/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CrmEntity } from './CrmEntity';
import type { MainEducation } from './MainEducation';
import type { MainEmployment } from './MainEmployment';

export type ContactRead = {
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    email?: string | null;
    primaryPhone?: string | null;
    mobilePhone?: string | null;
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    country?: CrmEntity;
    language?: ContactRead.language | null;
    receiveDMAcademyMag?: boolean | null;
    eventNewsAtDM?: boolean | null;
    sectorNewsletter?: boolean | null;
    newsLetterFromBIO?: boolean | null;
    magazineResearchForumCanRead?: boolean | null;
    magazineResearchForumSubcriber?: boolean | null;
    magazineDMPrintedSubscriber?: boolean | null;
    magazineDMStudPrintedSubscriber?: boolean | null;
    momentum?: boolean | null;
    isBioPillar?: boolean | null;
    isDigiPillar?: boolean | null;
    fullName?: string | null;
    sector?: CrmEntity;
    sectorInterest?: CrmEntity;
    agreementType?: string | null;
    agreementGroup?: string | null;
    phone?: string | null;
    mainEducation?: MainEducation;
    mainEmployment?: MainEmployment;
    hasCprRegistered?: boolean;
    status?: string | null;
    sectorInterests?: Array<string> | null;
    organisation?: ContactRead.organisation;
    streetname?: string | null;
    houseNumber?: string | null;
    floor?: string | null;
    houseLetter?: string | null;
    doorPlacement?: string | null;
    co?: string | null;
}

export namespace ContactRead {

    export enum language {
        DANSK = 'Dansk',
        ENGELSK = 'Engelsk',
    }

    export enum organisation {
        DM = 'DM',
        JA = 'JA',
        DSL = 'DSL',
    }


}