import { useEffect, useState } from "react";
import style from "./master-data.module.scss";
import * as api from "../../../api";
import LabelValue from "../../_GenericElements/LabelValue/label-value";
import { MasterDataAddressReadElement } from "../../../models/umbracoElement";
import Loader from "../../_GenericElements/Loader/loader";

export interface IMasterDataAddressReadElement {
  data: string;
}

type ContactRead = {
  fullName?: string;
  fullAddress?: string
}

const descriptionText = "Dit navn og adresse bliver hentet automatisk fra CPR-registeret. Hvis du har adresse beskyttelse eller flytter til udlandet, skal du skrive til dm@dm.dk.";

export const MasterDataAddressRead = (props: IMasterDataAddressReadElement) => {
    const [umbracoSettings] = useState<MasterDataAddressReadElement>(props.data ? JSON.parse(props.data) : {});
    const [readValue, setReadValues] = useState<ContactRead>({});
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        void getCurrentData();
    }, []);

    const getCurrentData = async () => {
        setLoading(true);
        const contact = await api.ContactsService.getContact();

        setReadValues({
            fullName: contact.fullName ?? "",
            fullAddress: createCompositeAddress(contact) ?? " ",
        });
        setLoading(false);
    };


    const createCompositeAddress = (contact: api.ContactRead) => {
        let addressComposite = contact.addressLine1;

        if (addressComposite) {
            let postalCityPart = "";

            if (contact.postalCode != null) {
                postalCityPart += contact.postalCode;
            }

            if (contact.city != null) {
                postalCityPart += (postalCityPart ? " " : "") + contact.city; // Add space if postalCode exists
            }

            if (postalCityPart) {
                addressComposite += ", " + postalCityPart;
            }

            return addressComposite;
        }

        // Build the CO part (if available)
        const coAddress = contact.co ? `c/o ${contact.co }` : null;

        // Build the composite address in the desired format
        const streetAndNumber = [
            contact.streetname,
            [contact.houseNumber, contact.houseLetter].filter(c => c).join(""),
        ].filter(c => c).join(" ");

        const floorAndDoor = [
            contact.floor ? `${contact.floor }.` : null,
            contact.doorPlacement,
        ].filter(c => c).join(" ");

        const postalAndCity = [
            contact.postalCode,
            contact.city,
        ].filter(c => c).join(" ");

        // Combine all parts into the desired format
        addressComposite = [
            coAddress,
            streetAndNumber,
            floorAndDoor,
            postalAndCity,
        ].filter(c => c).join(", ");

        return addressComposite;
    };

    let fields: { label: string, value?: string | null }[] = [
        { label: "Navn", value: readValue.fullName },
        { label: "Adresse", value: readValue.fullAddress },
    ];

    return <div className={style.root}>
        {
            isLoading ? <Loader/> :
                <>
                    <h2 className={style.header}>
                        {"Personoplysninger"}
                    </h2>
                    <table className={style.masterdata}>
                        <tbody>
                            {fields.map((f) => {
                                return <LabelValue trClassName={style.profilelabelvalue} key={f.label} label={f.label}
                                    value={f.value}></LabelValue>;
                            })}
                        </tbody>
                    </table>
                    <p style={{
                        fontSize: "17px",
                    }}>
                        {umbracoSettings.descriptionText || descriptionText}
                    </p>
                </>
        }
    </div>;
};
