export interface BaseUmbracoElement {
    contentTypeAlias: string;
    id: string;
}

export enum UmbracoChangeTypes {
    ToWork = "Til Arbejde",
    ToParentalLeave = "Til Barsel",
    ToPension = "Til Pension",
    ToAvailable = "Til Ledig",
}

export enum UmbracoWorkChangeTypes {
    NewJob = "Nyt arbejde",
    NewPosition = "Ny stilling"
}

export enum UmbracoIncidentsFilterType {
    ALLE = "Alle",
    MEDLEMSSAGER = "Medlemssager",
    TRSAGER = "TRSager",
}

export interface UmbracoIncidentsList extends Element {
    title: string,
    showArchived: boolean,
    previewSize: number,
    redirectLinkToForm: string,
    redirectLink: string,
    incidentType: UmbracoIncidentsFilterType,
    hideCreateButton:boolean,
}

export interface UmbracoIncidentOverview extends Element {
    incidentType: UmbracoIncidentsFilterType,
}
export interface NetworkListElement extends Element {
    dmLink: string,
    gaTilNetverk: string,
    broedTekst: string,
    netverkTekst: string,
    netverkOverskrift: string
}

export interface UmbracoIncidentForm extends Element {
    incidentType: UmbracoIncidentsFilterType,
    redirectLink: string,
    contentText:string,
    title:string

}

export interface NewsSubscriptionsFormElement extends Element {
    receiveDMAcademyMagLabel: string,
    receiveDMAcademyMagDescription: string,
    eventNewsAtDMLabel: string,
    eventNewsAtDMDescription: string,
    sectorNewsletterLabel: string,
    sectorNewsletterDescription: string,
    bioNyhedsbrevLabel: string,
    bioNyhedsbrevDescription: string,
    title: string,
}

export interface PrintedMagazinesFormElement extends Element {
    magazineResearchForumLabel: string,
    magazineResearchForumDescription: string,
    magazineDMPrintedLabel: string,
    magazineDMPrintedDescription: string,
    magazineDMStudPrintedLabel: string,
    magazineDMStudPrintedDescription: string,
    magazineDMDigitalSubscriptionLabel: string,
    magazineDMDigitalSubscriptionLink: string,
    magazineDMCommunicationLabel: string,
    magazineDMCommunicationDescription: string,
    magazineResearchForumDigitalSubscriptionLabel: string,
    magazineResearchforumDigitalSubscriptionLink: string,
    submitButtonLabel : string,
    momentumSubscriptionLabel: string,
    momentumSubscriptionDescription: string,
    title: string,
}

export interface pillarMembershipsFormElement extends Element {
    bIOPillarLabel: string,
    bIOPillarDescription: string,
    dIGIPillarLabel: string,
    dIGIPillarDescription: string,
    submitButtonLabel: string,
    title: string,
    subtitle: string,
    freeText: string,
}

export interface IncidentOverviewData extends Element {
}

export interface EventOverviewElement extends Element {
    fullbookedText:string,
    signupToWaitlistLabel:string,
    soldoutText:string,
    onWaitlistText:string,
    notTargetGroupText:string,
    materialTitle:string;
    registeredTitle:string;
    registeredText:string;
    waitlistRegisteredTitle:string;
    waitlistRegisteredText:string;
    missingPaymentTitle:string;
    missingPaymentText:string;
    moreDetailLinkTemplate:string;
    moreDetailLinkText:string;
    deadlineReached:string;

}

export interface EventListElement extends Element {
    mode: number
}

export interface PaymentGroupChangeElement extends Element {
    changeType: UmbracoChangeTypes
}

export interface WorkFormElement extends Element {
    workChangeType: UmbracoWorkChangeTypes;
    ansaettelsesstedLabel:string;
    ansaettelsestypeLabel:string;
    arbejderFuldtidLabel:string;
    cVRLabel:string;
    selvstaendigLabel:string;
    startDatoLabel:string;
    stillingLabel:string;
    tilfojAnsaettelsesstedLabel:string;
    virksomhedsadresseLabel:string;
    virksomhedsnavnLabel:string;
    contentText:string;
    vipTapLabel: string;
    workTimeLabel: string;
    arbejdsomradeLabel: string;
}
export interface FreelanceFormElement extends WorkFormElement{

}

export interface PoliticalGroupsElement extends Element {
    overskrift?:string;
    udvalgTekst?:string;

}
export interface RepresentativeSubmissionElement extends Element {
    consentTitle?:string;
    offentligHvervTekst?:string;
    personvernTekst?:string;

}
export interface WorkTimeFormElement extends Element {
    title?:string;
    workTimeLabel?:string;
    startDateLabel?:string;
    contentText?:string;
}
export interface ConsentListItem {
    consentItemText?: string
}
export interface RichtextElement extends Element {
    richtext?: string;
    title?:string;
    buttonLabel?:string;
    linkUrl?:string;
    richTextColor?:string;
}

export interface CTAWithPictureElement extends Element {
    richtext?: string;
    title?:string;
    buttonLabel?:string;
    linkUrl?:Link;
    richTextColor?:string;
    picture : UmbracoImage;
    picturePlacement: "Venstre" | "Højre";
    pictureAltText: string;
}

export interface UmbracoFile {
    cropUrls: any;
    focalPointUrlTemplate: string;
    src: string;
    focalPoint?: any;
    crops: any[];
}

export interface UmbracoImage {
    _creatorName: string;
    _url: string;
    _writerName: string;
    _hasChildren: boolean;
    _level: number;
    _createDate: Date;
    _id: string;
    _updateDate: Date;
    _links?: any;
    mediaTypeAlias: string;
    name: string;
    sortOrder: number;
    umbracoFile: UmbracoFile;
    umbracoWidth: number;
    umbracoHeight: number;
    umbracoBytes: number;
    umbracoExtension: string;
}

export interface NavigationElement {
    BackGroundColor:string;
    TextColor:string;
    NavigationItems:BreadCrumbElement[];
    Name:string;
}

export interface BreadCrumbElement {
    Url: string
    Title: string
    IsCurrent: boolean
    Children: BreadCrumbElement[]
}

export interface Element extends BaseUmbracoElement {
    text?: string;
    visibleTo?: any[];
    blockedGroups?: string[];
    backgroundColor?: string;
}

export interface GridElement extends Element {
    NumberOfRows: number;
    Children: Element;
}


export interface NewsSubscriptionsInfoElement extends Element {
    newsLettersFromDM: string;
    eventNewsatDM: string;
    sectorNewsletter: string;
    newsLettersFromBIO: string;
    receiveResearchForumDigitally: string;
    receiveDMAcademyMagazinePrinted: string;
    receiveDMAcademyMagazinePrintedStud: string;
    momentum: string;
    allNews: string;
    noneNews: string;
    allPrinted: string;
    nonePrinted: string;
    subscribeNews: string;
    subscribePrinted: string;
    nyhedsbreveLink: Link;
    bladLink: Link;
    gaTilNyhedsbreve: string;
    gaTilBlade: string;
    receiveBioNewsletter: string;
    receiveMomentum: string;
}


export interface ProfileElement extends Element {
    navnLabel : string;
    adresseLabel: string;
    postnummerLabel: string;
    emailLabel: string;
    telefonLabel: string;
    statusLabel: string;
    stillingLabel: string;
    firmaNavnLabel: string;
    ansaettelsesstedLabel: string;
    titelLabel: string;
    fagLabel: string;
    editLink:Link;
    paymentGroupName:string;
}


export interface CTACardElement extends Element {
    image: UmbracoImage;
    title: string;
    body: string;
    callToActionLink: Link;
}


export interface AvailableFormElement extends Element {
    startDatoLabel:string;
    modtagerDagpengeLabel: string;
    title:string;
    contentText:string;
}

export interface ParentalLeaveFormElement extends Element {
    barselSlutLabel: string
    barselStartLabel: string
    dagpengeSlutLabel: string
    dagpengeStartLabel: string
    contentText:string
    errorOverskrift: string
    errorTekst: string
}

export interface RetirementFormElement extends Element {
    pensionsTypeLabel: string
    startDatoLabel: string
    slutDatoLabel: string
    title: string
    contentText: string
}

export interface EndMembershipInfoElement extends Element {
    udmeldingsSide: Link;
    udmeldingsDato: string;
    udmeldingsArsag: string;
    udmeldingRegistrationDato:string;
    statusLabel: string;
    gaTilUdmeldingLobal: string;
    udmeldingOverskrift: string;
    udmeldingTekst: string;
}

export interface MySituationElement extends Element {
    situationsSide: Link;
    richtext : string;
}

export interface Link {
    _creatorName: string;
    _url: string;
    url: string;
    _writerName: string;
    _hasChildren: boolean;
    _level: number;
    _createDate: Date;
    _id: string;
    _updateDate: Date;
    _links?: any;
    contentTypeAlias: string;
    name: string;
    parentId: string;
    sortOrder: number;
    bottomtitle: string;
    bottomtext: string;
    bottomimage?: any;
    title: string;
    anonymousAccess: boolean;
    visibleTo: any[];
    hideFromNavigation: boolean;
    toptext: string;
    topimage?: any;
    elements: any[];
    target?:string;
}

export interface CreateAccountForm {
    title: string;
    emailLabel: string;
    cprLabel: string;
    passwordLabel: string;
    createButtonLabel: string;
    areYouMemberLabel: string;
    missingEmail: string;
    invalidEmail: string;
    missingPassword: string;
    tooShortPassword: string;
    badPassword: string;
    missingCpr: string;
    invalidCpr: string;
    createErrorMsg: string;
    successMsg: string;
    emailAlreadyFoundMsg: string;
    noMemberFoundMsg: string;
    alreadyMemberMsg: string;
    newUserRegTitle:string;
    newUserRegDescription:string;
    newUserLoginDescription:string;
    newUserLoginTitle:string;
    consentLink:string;
    confirmationText:string;
    consentText:string;
    passwordsNotMatch:string;
    missingConfirmPassword:string;
    backLink: { url: string, name:string}
}
export interface LoginFormElement {
    resetPasswordDescription: string;
    createAccountTitle:string;
    createAccountDescription:string;
    toCreateAccountButton:string;
    createAccountLink:string;
    text:string;// maintitle
    title:string;// signicattitle
    signicatLoginDescription:string;
    emailLoginTitle:string;
    emailLoginDescirption:string;
    toEmailLoginLabel:string;
    showNemId:boolean;
    resetLinkSentConfirmationText:string;
    boxColor:string;
    boxPadding:number;
}
export interface EmailConfirmationElement {
    title: string;
    confirmedMessage: string;
    errorMessage: string;
}
export interface SectorInterestElement extends Element {
   contentText:string,
   missingPrimarySectorText:string,
   missingContactInformationText:string,
   contactInformationLink:{url:string};
   submitButtonLabel:string;
}
export interface MasterDataSectorElement extends Element {
    contentText:string,
    submitButtonLabel:string;
 }

export interface MasterDataUpdateElement extends Element {
    contentText: string;
    submitButtonLabel: string;
 }

export interface MasterDataAddressReadElement extends Element {
  descriptionText: string;
}

export interface ChangePasswordFormElement {
    title: string;
    description: string;
    errorMsg: string;
    missingCurrentPassword: string;
    tooShortPassword: string;
    missingConfirmPassword: string;
    missingNewPassword: string;
    passwordsNotMatch: string;
    changeBtnLabel: string;
    contentAfterSuccess: string;
}

export interface ResetPasswordFormElement {
    title: string;
    description: string;
    contentAfterSuccess: string;
    errorMsg: string;
    tooShortPassword: string;
    missingConfirmPassword: string;
    missingPassword: string;
    passwordsNotMatch: string;
    resetBtnLabel: string;
}
export interface GeckoCalenderElement{
    calenderType:string;
}

export interface PbsRegistrationElement{
    contentText:string;
    title:string;
    code:string;
}

export interface RepresentativeElement{
    trsagRedirectLink:string;
    trsagLinkToForm:string;
    backToLink:{url:string};
    linkToForm:{url:string};
}
export interface AgreementInfoElement extends Element{
    textOnFreeOfCharge:string;
    showEndingDate:boolean;
    priceUnit:string;
}